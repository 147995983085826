import { PlayerState } from "./interfaces/state";

export const initialPlayerState: PlayerState = {
  currentPlaying: null,
  preMutedVolume: 100,
  playbackRate: 1,
  volume: 100,
  isPlaying: false,
  isReady: true,
  savedPositions: [],
  isBuffering: false,
  playerRef: null,
  headerRef: null,
  maxiPlayerRef: null,
  isFooterPlayerOpen: false,
  isPlayerMaximised: false,
  duration: "0:00",
  loadedProgress: 0,
  isMuted: false,
  playedSeconds: "0:00",
  playedProgress: 0,
  availableEpisodes: []
};
