import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import "typeface-poppins";

import { PlayerContextV2Provider } from "../../contexts/PlayerContextV2/PlayerContextV2";
import useIsMounted from "ismounted";
import Header, { SanityNavItem } from "../Header";
import { Banner as CMSBanner } from "../../interfaces/banner";
import Footer from "../Footer";
import Content from "../Content";
import CookieBar from "../CookieBar";

import { EmotionStyles } from "../../interfaces/emotion";
import ImageBanner from "../ImageBanner";
import SkipToMainLink from "../SkipToMainLink";

interface SanityLinksLandingPage {
  nodes: Array<{
    slug: {
      current: string;
    };
  }>;
}
export type CMSImageBanner = Pick<
  CMSBanner,
  "bannerImage" | "dismissedTab" | "id"
>;

interface Site {
  title: string;
  banner: CMSImageBanner;
}

interface Data {
  site: Site;
  allSanityLinksLandingPage: SanityLinksLandingPage;
  mainNav: { items: Array<SanityNavItem> };
  sitePage: { path: string };
  footerNav: { items: Array<SanityNavItem> };
}

const layoutStyles: EmotionStyles = {
  // helps ensure footer is always bottom if content doesn't fill vh
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  inner: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  }
};

const Layout: React.FC = ({ children }) => {
  const isMounted = useIsMounted();
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(false);

  const data: Data = useStaticQuery(graphql`
    query LayoutQuery {
      site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
        title
        banner {
          id
          bannerImage {
            asset {
              _id
            }
          }
          dismissedTab
        }
      }
      mainNav: sanityNavigation(title: { eq: "Main Nav" }) {
        items {
          path
          label
          _key
        }
      }
      footerNav: sanityNavigation(title: { eq: "Footer Nav" }) {
        items {
          path
          label
          _key
        }
      }
      allSanityLinksLandingPage {
        nodes {
          slug {
            current
          }
        }
      }
      sitePage {
        path
      }
    }
  `);

  const isNotLandingPage = () => {
    if (typeof window === "undefined") {
      return false;
    }
    const { nodes } = data.allSanityLinksLandingPage;
    let slugs: Array<string> = [];
    nodes.forEach(node => {
      slugs.push(node.slug.current);
    });
    const path = window.location.pathname.substr(1);

    return !slugs.includes(path);
  };

  const isEpisodePage = () => {
    if (typeof window === "undefined") {
      return false;
    }
    const episodePathRegex = /^\/episodes\/(.*)/;
    return episodePathRegex.test(location.pathname);
  };

  useEffect(() => {
    if (isMounted.current) {
      setShowFooter(isNotLandingPage);
    }
  }, [isMounted.current]);
  return (
    <PlayerContextV2Provider>
      <div css={layoutStyles.container}>
        <div css={layoutStyles.inner}>
          <SkipToMainLink />
          {data.site.banner && <ImageBanner {...data.site.banner} />}
          <Header siteTitle={data.site.title} navItems={data.mainNav.items} />
          <Content noFooter={!showFooter} fullPage={isEpisodePage()}>
            {children}
          </Content>
        </div>
        {showFooter && <Footer footerNav={data.footerNav.items} />}
      </div>
      <CookieBar />
    </PlayerContextV2Provider>
  );
};

export default Layout;
