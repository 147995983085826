import React from "react";
import Img from "gatsby-image/withIEPolyfill";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../../client-config";
import { CMSImage } from "../../interfaces/images";
import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";
import { CSSObject } from "@emotion/core";
import { GatsbyImageWithIEPolyfillProps } from "gatsby-image/withIEPolyfill";
import setTestId from "../../helpers/setTestId";

export interface FigureProps {
  node: CMSImage & { caption?: string };
  maxWidth?: number;
  styles?: CSSObject;
  GatsbyImageProps?: GatsbyImageWithIEPolyfillProps;
}

const figureStyles: EmotionStyles = {
  figure: {
    textAlign: "center",
    margin: 0
  },
  figcaption: {
    textAlign: "right",
    textTransform: "uppercase",
    fontSize: "0.75rem",
    marginTop: variables.spacing.small
  }
};

const Figure: React.FC<FigureProps> = ({
  node,
  maxWidth,
  styles,
  GatsbyImageProps
}) => {
  const { caption, alt, asset } = node;
  if (!asset) return null;
  const fluidProps = getFluidGatsbyImage(
    asset._id,
    { maxWidth },
    clientConfig.sanity
  );
  return (
    <figure
      css={[figureStyles.figure, styles]}
      style={maxWidth ? { maxWidth } : { maxWidth: 1200 }}
      {...setTestId("figure")}
    >
      <Img
        fluid={fluidProps}
        alt={alt}
        fadeIn={false}
        loading="eager"
        {...GatsbyImageProps}
      />
      {caption && (
        <figcaption css={figureStyles.figcaption}>{caption}</figcaption>
      )}
    </figure>
  );
};

export default Figure;
