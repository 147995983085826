import { SanityNavItem } from "../components/Header";

export const navItems: Array<SanityNavItem> = [
  {
    label: "Home",
    path: "/",
    _key: "navItemHome"
  },
  {
    label: "About",
    path: "/about",
    _key: "navItemAbout"
  },
  {
    label: "Useful Links",
    path: "/useful-links",
    _key: "navItemUsefulLinks"
  },
  {
    label: "Contact",
    path: "/contact",
    _key: "navItemContact"
  }
];
