import React from "react";
import { Link } from "gatsby";
import Button from "../Button";
import BMCLogo from "./components/BMCLogo";
import { trackBuyMeACoffee } from "../../helpers/trackingEvents";

import { EmotionStyles } from "../../interfaces/emotion";

const styles: EmotionStyles = {
  link: {
    textDecoration: "none"
  },
  button: {
    display: "flex",
    alignItems: "center"
  },
  icon: { marginLeft: 8 }
};

const BuyMeACoffee = () => {
  return (
    <p>
      Please consider supporting Why Mums Don't Jump:
      <br />
      <br />
      <Link to="https://www.buymeacoffee.com/whymumsdontjump" css={styles.link}>
        <Button
          type="secondary"
          cssStyles={styles.button}
          onClick={trackBuyMeACoffee}
        >
          Buy me a coffee <BMCLogo width="12" height="17" css={styles.icon} />
        </Button>
      </Link>
    </p>
  );
};

export default BuyMeACoffee;
