import React, { useState, useEffect } from "react";

import { IoPlaySharp as IconPlay } from "@react-icons/all-files/io5/IoPlaySharp";
import { IoPause as IconPause } from "@react-icons/all-files/io5/IoPause";

import { playButtonStyles as styles } from "../styles";

import { PlayButtonProps } from "../props";
import { CSSObject } from "@emotion/serialize";

const PlayButton: React.FC<PlayButtonProps> = ({
  handleClick,
  isPlaying,
  size = "regular",
  isLoading
}) => {
  const css: Array<CSSObject> = [
    styles[`icon_${size}`],
    !isPlaying && !isLoading ? styles.iconNotPlaying : {},
    size === "regular" && (!isPlaying || !isLoading)
      ? styles.iconSmallNotPlaying
      : {}
  ];
  const PlayIcon = () => <IconPlay css={css} />;
  const PauseIcon = () => <IconPause css={css} />;
  const [icon, setIcon] = useState(PlayIcon);

  useEffect(() => {
    if (isLoading) {
      setIcon(IconPlay);
    } else {
      setIcon(isPlaying ? PauseIcon : PlayIcon);
    }
  }, [isPlaying, isLoading]);

  return (
    <button
      css={[styles.button, styles[size]]}
      onClick={handleClick}
      aria-label={isPlaying ? "Pause" : "Play"}
    >
      {icon}
    </button>
  );
};

export default PlayButton;
