import emotionNormalize from "emotion-normalize";
import css from "@emotion/css";
import { colors } from "./colors";
import { fonts } from "./type";

export const globalStyles = css`
  ${emotionNormalize}
  * {
    box-sizing: border-box;
  }
  html,
  body {
    font-size: 16px;
    font-family: ${fonts.body};
    color: ${colors.body};
    @media (min-width: 740px) {
      :root {
        font-size: calc(1rem + ((1vw - 7.4px) * 0.2542));
        min-height: 0vw;
      }
    }
  }
  body {
    overflow-x: hidden;
  }
  body.navOpen {
    position: relative;
  }
  a {
    color: ${colors.link};
    transition: color 0.3s ease;
  }
  a:hover {
    color: ${colors.pinkDark};
  }
  p {
    font-size: 1rem;
    line-height: 31px;
    margin: 0 0 31px 0;
  }
  :focus:not(:focus-visible): {
    outlineColor: colors.black,
    outlineStyle: "none"  
  }
`;
