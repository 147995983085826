import useIsMounted from "ismounted";
import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player/lazy";
import { PlayerActionTypes } from "../../contexts/PlayerContextV2/interfaces/actions";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import { secondsToMinutes } from "../../helpers/dateTime";

const Player = () => {
  const {
    state: { volume, isPlaying, playbackRate, currentPlaying },
    dispatch
  } = usePlayer();
  const mounted = useIsMounted();
  const playerRef = useRef<ReactPlayer | null>(null);
  useEffect(() => {
    if (playerRef.current) {
      dispatch({
        type: PlayerActionTypes.SetPlayerRef,
        payload: playerRef.current
      });
    }
  }, [playerRef.current]);

  return mounted ? (
    <ReactPlayer
      url={currentPlaying?.episode.attributes.media_url}
      playing={isPlaying}
      playbackRate={playbackRate}
      onDuration={val => {
        dispatch({
          type: PlayerActionTypes.SetDurationState,
          payload: {
            field: "duration",
            value: secondsToMinutes(val.toString())
          }
        });
      }}
      onProgress={progress => {
        dispatch({
          type: PlayerActionTypes.SetPlayingState,
          payload: {
            field: "loadedProgress",
            value: progress.loaded
          }
        });
        dispatch({
          type: PlayerActionTypes.SetDurationState,
          payload: {
            field: "playedSeconds",
            value: secondsToMinutes(progress.playedSeconds.toString())
          }
        });
        dispatch({
          type: PlayerActionTypes.SetDurationState,
          payload: {
            field: "playedProgress",
            value: progress.played
          }
        });
      }}
      onBuffer={() =>
        dispatch({
          type: PlayerActionTypes.SetPlayingState,
          payload: {
            field: "isBuffering",
            value: true
          }
        })
      }
      onBufferEnd={() =>
        dispatch({
          type: PlayerActionTypes.SetPlayingState,
          payload: {
            field: "isBuffering",
            value: false
          }
        })
      }
      onReady={() => {
        dispatch({
          type: PlayerActionTypes.SetPlayingState,
          payload: {
            field: "isReady",
            value: true
          }
        });
      }}
      volume={volume / 100}
      ref={playerRef}
      height={0}
      width={0}
      autoPlay={false}
    />
  ) : null;
};

export default Player;
