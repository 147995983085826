import { CSSObject } from "@emotion/core";
import React from "react";
import { breakpointMixins } from "../../styles/breakpoints";
import { variables } from "../../styles/variables";

interface InnerProps {
  cssStyles?: Array<CSSObject>;
  collapseAll?: boolean;
}

const { spacing, centeredContainer } = variables;

const style: CSSObject = {
  ...centeredContainer,
  paddingLeft: spacing.medium,
  paddingRight: spacing.medium,
  [breakpointMixins.lg]: {
    paddingLeft: spacing.large,
    paddingRight: spacing.large
  }
};

const Inner: React.FC<InnerProps> = ({
  cssStyles = [],
  collapseAll,
  children
}) => {
  return (
    <div css={[style, ...cssStyles]} style={collapseAll ? { padding: 0 } : {}}>
      {children}
    </div>
  );
};

export default Inner;
