import { CSSObject } from "@emotion/core";
import React, { AriaAttributes, HTMLAttributes } from "react";
import setTestId from "../../helpers/setTestId";
import { styles } from "./styles";

export interface ButtonProps {
  type: "primary" | "secondary";
  onClick?: () => void;
  name?: string;
  active?: boolean;
  cssStyles?: CSSObject;
  role?: HTMLAttributes<HTMLButtonElement>["role"];
  ariaSelected?: AriaAttributes["aria-selected"];
  asWrapper?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  type,
  children,
  name,
  cssStyles,
  active,
  role,
  ariaSelected,
  asWrapper = false
}) => {
  if (asWrapper) {
    return (
      <span
        onClick={onClick}
        css={[styles.button, styles[type], styles["asWrapper"], cssStyles]}
        role={role}
        aria-selected={ariaSelected}
        {...setTestId(active ? "activeButton" : `button_${name}`)}
      >
        {children}
      </span>
    )
  }
  return (
    <button
      onClick={onClick}
      name={name}
      css={[styles.button, styles[type], cssStyles]}
      {...setTestId(active ? "activeButton" : `button_${name}`)}
      role={role ?? "button"}
      aria-selected={ariaSelected}
    >
      {children}
    </button>)
};

export default Button;
