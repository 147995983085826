import { EmotionStyles } from "../../../interfaces/emotion";
import { colors } from "../../../styles/colors";
import { variables } from "../../../styles/variables";
import { textStyles } from "../../../styles/type";
import { breakpointMixins } from "../../../styles/breakpoints";

const { spacing } = variables;
export const styles: EmotionStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: colors.white,
    [breakpointMixins.md]: {
      display: "grid",
      gridGap: spacing.medium,
      gridTemplateColumns: "200px 1fr"
    },
    [breakpointMixins.lg]: {
      // gridGap: spacing.large,
      gridTemplateColumns: "minMax(150px, 220px)  1fr 90px"
    }
  },
  title: {
    color: colors.black,
    paddingTop: 8,
    margin: `0 0 20px`,
    textTransform: "uppercase",
    fontStyle: "none",

    "& a": {
      textDecoration: "none",
      color: colors.black
    }
  },
  main: {
    wordBreak: "break-word",
    ...textStyles.bodyS
  },
  episodeContent: {
    height: "100%"
  },
  tile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    [breakpointMixins.md]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  playButtonSmall: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%",
    [breakpointMixins.lg]: {
      display: "none"
    }
  },
  playButtonLarge: {
    height: "100%",
    display: "none",
    [breakpointMixins.lg]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
};

export const imageTileStyles: EmotionStyles = {
  container: {
    minWidth: 100,
    width: "50%",

    [breakpointMixins.md]: {
      minWidth: 200,
      minHeight: 200,
      width: "auto"
    }
  }
};

export const iconsBarStyles: EmotionStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    marginTop: spacing.medium,

    [breakpointMixins.lg]: {
      alignItems: "flex-start"
    }
  },
  icon: {
    marginRight: spacing.small,
    color: colors.black,
    cursor: "pointer"
  },
  link: {
    textDecoration: "none",
    color: colors.black,
    display: "flex",
    alignItems: "center"
  },
  label: {
    ...textStyles.bodyXs,
    marginRight: spacing.medium,

    [breakpointMixins.md]: {
      ...textStyles.bodyS,
      marginRight: spacing.large
    }
  }
};
