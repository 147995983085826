import React, { useEffect, useRef } from "react";

import Player from "../Player";
import PlayerMinimised from "../Player/components/PlayerMinimised";

import { footerStyles as styles } from "./styles";

import EpisodePage from "../EpisodePage";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import { useFooterPlayer } from "./useFooterPlayer";
import { PlayerActionTypes } from "../../contexts/PlayerContextV2/interfaces/actions";
import {
  handleDismissPlayer,
  handleMaximisePlayer,
  handleMinimizePlayer
} from "../Player/components/helpers";
import BasicFooter from "../Footer/components/BasicFooter";
import { graphql, useStaticQuery } from "gatsby";
import { NodesInterface } from "../../interfaces/blogPost";
import { Transcript } from "../../interfaces/transcript";
import { SanityNavItem } from "../Header";

interface FooterPlayerProps {
  bottomPos: number;
  headerBottom?: number;
}

const FooterPlayer: React.FC<FooterPlayerProps> = ({
  bottomPos,
  headerBottom
}) => {
  const {
    state: { currentPlaying, isFooterPlayerOpen, isPlayerMaximised },
    dispatch
  } = usePlayer();
  const { handleClose } = useFooterPlayer();
  const data: {
    transcripts: NodesInterface<Transcript>;
    footerNav: { items: Array<SanityNavItem> };
  } = useStaticQuery(graphql`
    query FooterTranscriptQuery {
      transcripts: allSanityTranscript {
        nodes {
          id
          episode {
            episode
          }
          createTranscriptPage
        }
      }
      footerNav: sanityNavigation(title: { eq: "Footer Nav" }) {
        items {
          path
          label
          _key
        }
      }
    }
  `);
  const maxiPlayerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    dispatch({
      type: PlayerActionTypes.SetMaxiPlayerRef,
      payload: maxiPlayerRef.current || null
    });

    return () =>
      dispatch({
        type: PlayerActionTypes.SetMaxiPlayerRef,
        payload: null
      });
  }, [maxiPlayerRef.current, footerRef.current]);

  return (
    <aside
      css={[styles.container, isPlayerMaximised && styles.containerMaximised]}
      style={{
        bottom: bottomPos,
        top:
          headerBottom && (isFooterPlayerOpen || !isPlayerMaximised)
            ? "auto"
            : headerBottom
      }}
      title="footer player"
    >
      <div css={styles.inner}>
        <PlayerMinimised
          {...currentPlaying}
          isHidden={!isFooterPlayerOpen || isPlayerMaximised}
          handleDismiss={() => {
            handleDismissPlayer(dispatch);
          }}
          handleMaximise={() => {
            handleMaximisePlayer(dispatch);
          }}
        />

        {isPlayerMaximised && currentPlaying?.episode && !isFooterPlayerOpen && (
          <div ref={maxiPlayerRef}>
            <div css={styles.maxiPlayerContainer}>
              <EpisodePage
                episode={currentPlaying.episode}
                onMinimize={() => {
                  handleMinimizePlayer(dispatch);
                }}
                onDismiss={() => {
                  handleClose();
                  handleDismissPlayer(dispatch);
                }}
                transcripts={data.transcripts.nodes}
                fromFooter
              />
            </div>
          </div>
        )}
        <Player />
      </div>
      {isPlayerMaximised ? (
        <div css={styles.footerContainer}>
          <BasicFooter footerRef={footerRef} footerNav={data.footerNav.items} />
        </div>
      ) : null}
    </aside>
  );
};

export default FooterPlayer;
