import { format } from "date-fns";

export const secondsToMinutes: (inputSeconds: string) => string = (
  inputSeconds
) => {
  const secs = parseInt(inputSeconds, 10);
  let minutes = Math.floor(secs / 60);
  let seconds = secs - minutes * 60;
  let displayMinutes = minutes.toString();
  let displaySeconds = seconds.toString();

  if (10 > minutes) {
    displayMinutes = `0${minutes}`;
  }
  if (10 > seconds) {
    displaySeconds = `0${seconds}`;
  }

  // Return display.
  return `${displayMinutes}:${displaySeconds}`;
};

export const formatDate = (
  date: Date | string,
  formatter: string = "MMMM do, yyyy"
) => {
  return format(new Date(date), formatter);
};
