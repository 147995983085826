import { headerStyles } from "../components/Header/styles";
import { EmotionStyles } from "../interfaces/emotion";
import { breakpointMixins } from "./breakpoints";
import { colors } from "./colors";
import { textStyles } from "./type";
import { variables } from "./variables";

const { spacing } = variables;
export const contentStyles: EmotionStyles = {
  "& blockquote": {
    ...textStyles.bodyM,
    borderLeft: `2px solid ${colors.primary}`,
    margin: `${spacing.large} 0`,
    paddingLeft: spacing.medium,
    fontStyle: "italic"
  },
  "& ul, ol": {
    margin: `${spacing.large} 0`,
    marginLeft: spacing.medium,
    padding: 0,
    paddingLeft: spacing.medium
  },
  "& li": {
    marginBottom: spacing.large,
    ...textStyles.bodyM
  },
  "& h2, h3, h4, h5": headerStyles,
  "& figure": {
    marginTop: "2rem",
    marginBottom: "3rem",

    [breakpointMixins.lg]: {
      marginBottom: "4rem"
    }
  }
};
