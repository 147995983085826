import { Transcript } from "../interfaces/transcript";

export const hasTranscript = (
  title: string,
  transcripts: Array<Transcript>
) => {
  if (transcripts.length === 0) return false;

  return Boolean(transcripts.find((t) => t.episode?.episode === title));
};
