import React, { useCallback } from "react";
import { Scrubber } from "react-scrubber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";

import { usePlayer } from "../../../contexts/PlayerContextV2/PlayerContextV2";
import { trackPlayButtonEvent } from "../../../helpers/trackingEvents";
import { PlayerActionTypes } from "../../../contexts/PlayerContextV2/interfaces/actions";
import PlayButton from "./PlayButton";
import SkipButtons from "./SkipButtons";
import TimeElapsed from "./TimeElapsed";
import PlaybackRateButton from "./PlaybackRateButton";

import { playerStyles, scrubberStyles } from "../styles";
import { playerMinimisedStyles as styles } from "./playerMinimisedStyles";
import MinimizeDismissControls from "../../MinimizeDismissControls";

interface PlayerMinimisedProps {
  handleMaximise: () => void;
  handleDismiss: () => void;
  isHidden: boolean;
}

const PlayerMinimised: React.FC<PlayerMinimisedProps> = ({
  isHidden,
  handleDismiss,
  handleMaximise
}) => {
  const {
    state: {
      currentPlaying,
      isPlaying,
      isBuffering,
      playedProgress,
      loadedProgress,
      playbackRate,
      isMuted,
      playedSeconds,
      duration
    },
    dispatch
  } = usePlayer();

  const handlePlayEvent = useCallback(() => {
    trackPlayButtonEvent(isPlaying, currentPlaying);
  }, [currentPlaying]);

  return isHidden ? null : (
    <div css={styles.container}>
      <div css={[playerStyles.skipButtons, styles.controls]}>
        <SkipButtons
          handleClick={params =>
            dispatch({ type: PlayerActionTypes.Skip, payload: params })
          }
        >
          <PlayButton
            isPlaying={isPlaying}
            handleClick={() => {
              dispatch({
                type: PlayerActionTypes.SetPlayingState,
                payload: { field: "isReady", value: false }
              });
              dispatch({
                type: PlayerActionTypes.SetPlayingState,
                payload: { field: "isPlaying", value: !isPlaying }
              });
              handlePlayEvent();
            }}
            isLoading={isBuffering}
            currentPlaying={currentPlaying}
          />
        </SkipButtons>
      </div>

      <div css={styles.header}>
        S{currentPlaying?.episode.attributes.season}-
        {currentPlaying?.episode.attributes.title &&
          currentPlaying.episode.attributes.title}
      </div>
      <div css={styles.time}>
        {duration && playedSeconds && (
          <TimeElapsed elapsed={playedSeconds} totalDuration={duration} />
        )}
      </div>
      <div css={styles.scrubber}>
        <Scrubber
          css={scrubberStyles}
          min={0}
          max={1}
          value={playedProgress}
          bufferPosition={loadedProgress}
          onScrubStart={val =>
            dispatch({ type: PlayerActionTypes.Scrub, payload: val })
          }
          onScrubChange={value =>
            dispatch({
              type: PlayerActionTypes.SetDurationState,
              payload: { field: "playedProgress", value }
            })
          }
          onScrubEnd={val =>
            dispatch({ type: PlayerActionTypes.Scrub, payload: val })
          }
        />
      </div>
      <div css={styles.soundStateIcons}>
        <PlaybackRateButton
          rate={playbackRate}
          handleOnClick={() =>
            dispatch({ type: PlayerActionTypes.SetPlayBackRate })
          }
          css={styles.icon}
        />
        <FontAwesomeIcon
          icon={isMuted ? faVolumeMute : faVolumeUp}
          css={[styles.volumeIcon, styles.icon]}
          onClick={() =>
            dispatch({
              type: PlayerActionTypes.HandleMute,
              payload: { muteIt: isMuted }
            })
          }
        />
      </div>
      <MinimizeDismissControls
        onMaximize={handleMaximise}
        onDismiss={handleDismiss}
        containerStyles={styles.displayStateIcons}
      />
    </div>
  );
};

export default PlayerMinimised;
