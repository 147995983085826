const setTestId = (testId?: string) => {
  if (!testId) {
    return null;
  }

  return process.env.GATSBY_DEACTIVATE_TEST_IDS
    ? null
    : { "data-testid": testId };
};

export default setTestId;
