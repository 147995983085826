import React, { useEffect, useState } from "react";

import { Link } from "gatsby";
import { useCookies } from "react-cookie";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import { stringToBoolean } from "../../helpers/stringToBoolean";
import { styles } from "./cookieBarStyles";
import Button from "../Button";

const CookieBar = () => {
  const [cookies, setCookies] = useCookies();
  const [showBar, setShowBar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowBar(
      !stringToBoolean(cookies.cnAccepted) && !process.env.GATSBY_CLOUD
    );
  }, [cookies]);

  const handleCookies = () => {
    if (!stringToBoolean(cookies.cnAccepted)) {
      setCookies("cnAccepted", true, {
        expires: new Date("Thu, 31 Dec 2099 23:59:59 UTC")
      });
    }
    if (!stringToBoolean(cookies.wmdjFc)) {
      setCookies("wmdjFc", true, {
        expires: new Date("Thu, 31 Dec 2099 23:59:59 UTC")
      });
    }
    if (!stringToBoolean(cookies.wmdjPc)) {
      setCookies("wmdjPc", true, {
        expires: new Date("Thu, 31 Dec 2099 23:59:59 UTC")
      });
    }
    initializeAndTrack(location);
  };

  return showBar ? (
    <div css={styles.container}>
      <div css={styles.bar}>
        <div css={styles.inner}>
          <div>
            <p>
              We use cookies to improve your experience on this site and to
              analyse web traffic. At no time do we store any personally
              identifiable data.
            </p>
            <p>
              You can{" "}
              <Link to="/our-use-of-cookies-local-storage-and-other-technologies">
                find out more about or use of cookies and other technologies
                here.
              </Link>
            </p>
          </div>
          <div css={styles.buttonsContainer}>
            <div>
              <Button
                type="primary"
                onClick={() => handleCookies()}
                cssStyles={styles.acceptButton}
              >
                I understand
              </Button>
              <Link
                to="/cookie-preferences"
                css={styles.prefsLink}
                onClick={() =>
                  setCookies("cnAccepted", true, {
                    expires: new Date("Thu, 31 Dec 2099 23:59:59 UTC")
                  })
                }
              >
                Manage preferences
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CookieBar;
