import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from "body-scroll-lock";
import { useLayoutEffect, useRef, useState } from "react";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import useWindowSize from "../../hooks/useWindowSize";
import { breakpoints } from "../../styles/breakpoints";

export const useNavigation = () => {
  const { dispatch } = usePlayer();
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef<HTMLElement | null>(null);
  const windowSize = useWindowSize();
  const mdSizeUp = breakpoints.find((breakpoint) => breakpoint.key === "md");

  const handleOpen = () => {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    if (navRef.current) {
      disableBodyScroll(navRef.current);
    }
    document.body.classList.add("navOpen");
  };

  const handleClose = () => {
    if (navRef.current) {
      enableBodyScroll(navRef.current);
    }
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.body.classList.remove("navOpen");
  };

  useLayoutEffect(() => {
    if (navRef && navRef.current) {
      isOpen ? handleOpen() : handleClose();
    }
    if (windowSize.width && mdSizeUp && windowSize.width >= mdSizeUp.value) {
      setIsOpen(false);
      handleClose();
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen, navRef, windowSize.width]);

  return { isOpen, setIsOpen, dispatch, navRef };
};
