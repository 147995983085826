import React, { useEffect, useState } from "react";

import { usePlayer } from "../../../../contexts/PlayerContextV2/PlayerContextV2";
import { PlayerActionTypes } from "../../../../contexts/PlayerContextV2/interfaces/actions";
import { trackPlayButtonEvent } from "../../../../helpers/trackingEvents";

import PlayButton from "../PlayButton";
import SkipButtons from "../SkipButtons";
import PlaybackRateButton from "../PlaybackRateButton";

import { inlinePlayerStyles as styles } from "./inlinePlayerStyles";
import { scrubberStyles } from "../../styles";
import { Scrubber } from "react-scrubber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { TransistorEpisodeInterface } from "../../../../interfaces/podcasts";
import { getEpisodePath } from "../../../../helpers/getEpisodePath";

interface InlinePLayerProps {
  episode: TransistorEpisodeInterface;
  openFooterPlayerOnPlay?: boolean;
}

const InlinePlayer: React.FC<InlinePLayerProps> = ({
  episode,
  openFooterPlayerOnPlay
}) => {
  const [isEpPlaying, setIsEpPlaying] = useState<boolean | null>(false);

  const {
    state: {
      isPlaying,
      isBuffering,
      currentPlaying,
      playedProgress,
      loadedProgress,
      playbackRate,
      isMuted
    },
    dispatch
  } = usePlayer();

  useEffect(() => {
    setIsEpPlaying(
      isPlaying && currentPlaying && currentPlaying.episode.id === episode.id
    );
  }, [isPlaying, episode, currentPlaying]);

  useEffect(() => {
    if (openFooterPlayerOnPlay && isEpPlaying) {
      dispatch({
        type: PlayerActionTypes.SetFooterPlayerOpen,
        payload: true
      });
    }
  }, [isEpPlaying, openFooterPlayerOnPlay]);

  const handlePlayButton = () => {
    dispatch({
      type: PlayerActionTypes.HandleInlinePlayerPlay,
      payload: {
        currentPlaying: {
          episode,
          episodePath: getEpisodePath(episode)
        },
        forcePlay: Boolean(
          currentPlaying && currentPlaying.episode.id !== episode.id
        )
      }
    });
    trackPlayButtonEvent(!isEpPlaying, currentPlaying);
  };

  return (
    <div css={styles.container}>
      <div css={styles.playControls}>
        <SkipButtons
          handleClick={params =>
            dispatch({ type: PlayerActionTypes.Skip, payload: params })
          }
        >
          <PlayButton
            isPlaying={Boolean(isEpPlaying)}
            handleClick={() => {
              handlePlayButton();
            }}
            isLoading={isBuffering}
            currentPlaying={currentPlaying}
          />
        </SkipButtons>
      </div>
      <div css={styles.scrubber}>
        <Scrubber
          css={scrubberStyles}
          min={0}
          max={1}
          value={
            currentPlaying && currentPlaying.episode.id === episode.id
              ? playedProgress
              : 0
          }
          bufferPosition={loadedProgress}
          onScrubStart={val =>
            dispatch({ type: PlayerActionTypes.Scrub, payload: val })
          }
          onScrubChange={value =>
            dispatch({
              type: PlayerActionTypes.SetDurationState,
              payload: { field: "playedProgress", value }
            })
          }
          onScrubEnd={val =>
            dispatch({ type: PlayerActionTypes.Scrub, payload: val })
          }
        />
      </div>
      <div css={styles.soundStateIcons}>
        <PlaybackRateButton
          rate={playbackRate}
          handleOnClick={() =>
            dispatch({ type: PlayerActionTypes.SetPlayBackRate })
          }
          css={styles.icon}
        />
        <FontAwesomeIcon
          icon={isMuted ? faVolumeMute : faVolumeUp}
          css={[styles.volumeIcon, styles.icon]}
          onClick={() =>
            dispatch({
              type: PlayerActionTypes.HandleMute,
              payload: { muteIt: isMuted }
            })
          }
        />
      </div>
    </div>
  );
};

export default InlinePlayer;
