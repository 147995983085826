import { useContext } from "react";
import { createStore } from "../helpers/createStore";
import { initialPlayerState } from "./initialPlayerState";
import { playerReducer } from "./playerReducer";

const [context, PlayerProvider] = createStore(
  playerReducer,
  initialPlayerState
);
export const usePlayer = () => useContext(context);
export const PlayerContextV2Provider = PlayerProvider;
