import { variables } from "../../../styles/variables";
import { EmotionStyles } from "../../../interfaces/emotion";
import { colors } from "../../../styles/colors";
import { fontWeights } from "../../../styles/type";
import { breakpointMixins } from "../../../styles/breakpoints";

export const headerStyles: EmotionStyles = {
  headerContainer: {
    width: "100vw",
    display: "flex",
    justifyContent: "center"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${variables.spacing.xSmall} ${variables.spacing.medium}`,
    maxWidth: variables.maxWidth,

    [breakpointMixins.lg]: {
      padding: `${variables.spacing.large} ${variables.spacing.large} ${variables.spacing.small}`
    }
  },
  titleLink: {
    textDecoration: "none",
    color: colors.heading,

    "& :hover": {
      color: colors.heading
    }
  },
  title: {
    fontSize: 30,
    fontStyle: "italic",
    fontWeight: fontWeights.heavy,
    padding: `${variables.spacing.xSmall} 0`
  }
};
