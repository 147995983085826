import React from "react";
import slugify from "slugify";
import TabButton, { TabButtonProps } from "./components/TabButton";
import { styles } from "./styles";

interface TabButtonsProps {
  buttons: Array<TabButtonProps>;
}

const TabButtons: React.FC<TabButtonsProps> = ({ buttons }) => {
  return (
    <div css={styles.tabButtonContainer} role="tablist">
      {buttons.map((button, i) => (
        <TabButton key={`${slugify(button.description)}_${i}`} {...button} />
      ))}
    </div>
  );
};

export default TabButtons;
