import { EmotionStyles } from "../../../../interfaces/emotion";
import { colors } from "../../../../styles/colors";
import { variables } from "../../../../styles/variables";

const { spacing } = variables;

export const inlinePlayerStyles: EmotionStyles = {
  container: {
    border: `1px solid ${colors.lightGrey}`,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${spacing.medium} ${spacing.small}`
  },
  scrubber: {
    flex: 1,
    margin: `0 ${spacing.medium}`,

    "& .scrubber.horizontal .bar": {
      top: 0,
      transform: "none"
    }
  }
};
