import ReactPlayer from "react-player";
import { SkipParams } from "../../../components/Player/props";
import {
  CurrentPlaying,
  TransistorEpisodeInterface
} from "../../../interfaces/podcasts";
import { PlayerState } from "./state";

export interface SavedPosition {
  guid: string;
  seconds: number;
}

export enum PlayerActionTypes {
  Reset = "RESET",
  SetCurrentPlaying = "SET_CURRENT_PLAYING",
  SetVolume = "SET_VOLUME",
  SetPlayBackRate = "SET_PLAYBACK_RATE",
  SetPlayingState = "SET_PLAYING_STATE",
  SetDurationState = "SET_DURATION_STATE",
  SetSavedPositions = "SET_SAVED_POSITIONS",
  SetPlayerRef = "SET_PLAYER_REF",
  SetHeaderRef = "SET_HEADER_REF",
  SetMaxiPlayerRef = "SET_MAXI_PLAYER_REF",
  SetFooterPlayerOpen = "SET_FOOTER_PLAYER_OPEN",
  SetPlayerMaximised = "SET_PLAYER_MAXIMISED",
  Scrub = "SCRUB",
  Skip = "SKIP",
  HandleMute = "HANDLE_MUTE",
  DismissPlayer = "DISMISS_PLAYER",
  MaximisePlayer = "MAXIMISE_PLAYER",
  MinimisePlayer = "MINIMISE_PLAYER",
  HandleEpisodePlay = "HANDLE_EPISODE_PLAY",
  HandleInlinePlayerPlay = "HANDLE_INLINE_PLAYER_PLAY",
  UpdateAvailableEpisodes = "UPDATE_AVAILABLE_EPISODES"
}

interface PlayerAction<Type> {
  type: Type;
}

interface GenericPlayerAction<Type, State> extends PlayerAction<Type> {
  payload: {
    field: keyof State;
    value: boolean | number | string;
  };
}

export type ResetAction = PlayerAction<PlayerActionTypes.Reset>;

interface SetCurrentPlayingAction
  extends PlayerAction<PlayerActionTypes.SetCurrentPlaying> {
  payload: CurrentPlaying;
}

interface SetVolumeAction extends PlayerAction<PlayerActionTypes.SetVolume> {
  payload: {
    volume: number;
    preMuted: boolean;
  };
}
type SetPlaybackRateAction = PlayerAction<PlayerActionTypes.SetPlayBackRate>;

export type PlayingState = Pick<
  PlayerState,
  "isReady" | "isPlaying" | "isBuffering" | "isMuted" | "loadedProgress"
>;

type SetPlayingStateAction = GenericPlayerAction<
  PlayerActionTypes.SetPlayingState,
  PlayingState
>;

interface SetSavedPositionsAction
  extends PlayerAction<PlayerActionTypes.SetSavedPositions> {
  payload: Array<SavedPosition>;
}

interface SetPlayerRefAction
  extends PlayerAction<PlayerActionTypes.SetPlayerRef> {
  payload: ReactPlayer | null;
}

interface SetHeaderRefAction
  extends PlayerAction<PlayerActionTypes.SetHeaderRef> {
  payload: HTMLDivElement | null;
}

interface SetMaxiPlayerRefAction
  extends PlayerAction<PlayerActionTypes.SetMaxiPlayerRef> {
  payload: HTMLDivElement | null;
}

interface SetFooterPlayerOpenAction
  extends PlayerAction<PlayerActionTypes.SetFooterPlayerOpen> {
  payload: boolean;
}

interface SetPlayerMaximisedAction
  extends PlayerAction<PlayerActionTypes.SetPlayerMaximised> {
  payload: boolean;
}

type DurationState = Pick<
  PlayerState,
  "playedProgress" | "playedSeconds" | "duration"
>;

type SetDurationStateAction = GenericPlayerAction<
  PlayerActionTypes.SetDurationState,
  DurationState
>;

interface ScrubAction extends PlayerAction<PlayerActionTypes.Scrub> {
  payload: number;
}

interface SkipAction extends PlayerAction<PlayerActionTypes.Skip> {
  payload: SkipParams;
}

interface HandleMuteAction extends PlayerAction<PlayerActionTypes.HandleMute> {
  payload: { muteIt: boolean };
}

type DismissPlayerAction = PlayerAction<PlayerActionTypes.DismissPlayer>;
type MaximisePlayerAction = PlayerAction<PlayerActionTypes.MaximisePlayer>;
type MinimisePlayerAction = PlayerAction<PlayerActionTypes.MinimisePlayer>;
interface HandleEpisodePlayAction
  extends PlayerAction<PlayerActionTypes.HandleEpisodePlay> {
  payload: {
    play: boolean;
  };
}
interface HandleInlinePlayerPlay
  extends PlayerAction<PlayerActionTypes.HandleInlinePlayerPlay> {
  payload: {
    currentPlaying: CurrentPlaying;
    forcePlay?: boolean;
  };
}

interface UpdateAvailableEpisodesAction
  extends PlayerAction<PlayerActionTypes.UpdateAvailableEpisodes> {
  payload: Array<TransistorEpisodeInterface>;
}

export type PlayerStoreAction =
  | ResetAction
  | SetCurrentPlayingAction
  | SetVolumeAction
  | SetPlaybackRateAction
  | SetPlayingStateAction
  | SetSavedPositionsAction
  | SetPlayerRefAction
  | SetHeaderRefAction
  | SetMaxiPlayerRefAction
  | SetDurationStateAction
  | SetFooterPlayerOpenAction
  | SetPlayerMaximisedAction
  | ScrubAction
  | SkipAction
  | HandleMuteAction
  | DismissPlayerAction
  | MaximisePlayerAction
  | MinimisePlayerAction
  | HandleEpisodePlayAction
  | HandleInlinePlayerPlay
  | UpdateAvailableEpisodesAction;

export type PlayerReducer = (
  state: PlayerState,
  action: PlayerStoreAction
) => PlayerState;
