import { findBreakpoint } from "../helpers/findBreakpoints";

export type BreakpointSize = "base" | "sm" | "ms" | "md" | "lg" | "xl";

export interface Breakpoint {
  key: BreakpointSize;
  value: number;
}

export const breakpoints: Array<Breakpoint> = [
  { key: "base", value: 0 },
  { key: "sm", value: 330 },
  { key: "ms", value: 420 },
  { key: "md", value: 740 },
  { key: "lg", value: 960 },
  { key: "xl", value: 1078 }
];

export const breakpointMixins = {
  base: `@media only screen and (min-width: ${findBreakpoint(
    breakpoints,
    "base"
  )}px)`,
  sm: `@media only screen and (min-width: ${findBreakpoint(
    breakpoints,
    "sm"
  )}px)`,
  ms: `@media only screen and (min-width: ${findBreakpoint(
    breakpoints,
    "ms"
  )}px)`,
  md: `@media only screen and (min-width: ${findBreakpoint(
    breakpoints,
    "md"
  )}px)`,
  lg: `@media only screen and (min-width: ${findBreakpoint(
    breakpoints,
    "lg"
  )}px)`,
  xl: `@media only screen and (min-width: ${findBreakpoint(
    breakpoints,
    "xl"
  )}px)`
};
