import React from "react";

import { TimeElapsedProps } from "../props";

import { timeElapsedSyles as styles } from "../styles";

const TimeElapsed: React.FC<TimeElapsedProps> = ({
  totalDuration,
  elapsed
}) => {
  return (
    <span css={styles.container}>
      {elapsed} <span css={styles.durationTotal}>| {totalDuration}</span>
    </span>
  );
};

export default TimeElapsed;
