export const baseColors = {
  pinkDark: "#FC4966",
  pinkLight: "#FCD6E1",
  white: "#FFFFFF",
  grey: "#AAAAAA",
  darkGrey: "#444444",
  lightGrey: "#E5E5E5",
  black: "#202120",
  whamPink: "#FC4966",
  grey5: "#E0E0E0",
  coralPink: "#FDA4AE",
  blushPink: "#FDD9E3",
  bookBlack: "#0d222b"
};

export const colors = {
  ...baseColors,
  primary: baseColors.pinkLight,
  secondary: baseColors.pinkLight,
  link: baseColors.black,
  heading: baseColors.black,
  footer: baseColors.white,
  body: baseColors.black
};
