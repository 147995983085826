import React, { Fragment } from "react";
import { BsCloudDownload } from "@react-icons/all-files/bs/BsCloudDownload";
import { GoPlus } from "@react-icons/all-files/go/GoPlus";

import { iconsBarStyles as styles } from "../styles";
import { Link } from "gatsby";
import slugify from "slugify";
import setTestId from "../../../helpers/setTestId";

export interface IconsBarProps {
  mediaUrl: string;
  title: string;
  episodePath?: string;
  downloadText?: string;
}

const IconsBar: React.FC<IconsBarProps> = ({
  mediaUrl,
  title,
  episodePath,
  downloadText
}) => {
  const ids = { download: `download_${slugify(title)}`, showNotes: `showNotes_${slugify(title)}` }

  return (
    <Fragment>
      <div css={styles.container}>
        <a
          href={`${mediaUrl}?download=true`}
          target="_download"
          title={`Download ${title}`}
          download
          css={styles.link}
          {...setTestId("downloadLink")}
        >
          <BsCloudDownload css={styles.icon} aria-labelledby={ids.download} {...setTestId("downloadIcon")} />
          <span id={ids.download} css={styles.label}>{downloadText || "Download"}</span>
        </a>
        {episodePath && (
          <Link to={episodePath} css={styles.link}>
            <GoPlus css={styles.icon} aria-labelledby={ids.showNotes} {...setTestId("showNotesIcon")} />
            <span css={styles.label} id={ids.showNotes}>Find out more</span>
          </Link>
        )}
      </div>
    </Fragment>
  );
};

export default IconsBar;
