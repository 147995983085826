import { PlaybackRate } from "../../interfaces/podcasts";
import { initialPlayerState } from "./initialPlayerState";
import { PlayerActionTypes, PlayerReducer } from "./interfaces/actions";

export const playerReducer: PlayerReducer = (state, action) => {
  switch (action.type) {
    case PlayerActionTypes.Reset:
      return {
        ...initialPlayerState
      };

    case PlayerActionTypes.SetCurrentPlaying:
      return {
        ...state,
        currentPlaying: action.payload
      };

    case PlayerActionTypes.SetVolume:
      if (action.payload.preMuted) {
        return {
          ...state,
          preMutedVolume: action.payload.volume
        };
      }
      return {
        ...state,
        volume: action.payload.volume
      };

    case PlayerActionTypes.SetPlayBackRate:
      const playbackRates: Array<PlaybackRate> = [1, 1.2, 1.5, 1.75, 2];
      const rateIndex = playbackRates.indexOf(state.playbackRate);
      return {
        ...state,
        playbackRate:
          rateIndex === playbackRates.length - 1
            ? playbackRates[0]
            : playbackRates[rateIndex + 1]
      };

    case PlayerActionTypes.SetPlayingState:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };

    case PlayerActionTypes.SetDurationState:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };

    case PlayerActionTypes.SetSavedPositions:
      return {
        ...state,
        savedPositions: action.payload
      };

    case PlayerActionTypes.SetPlayerRef:
      return {
        ...state,
        playerRef: action.payload
      };

    case PlayerActionTypes.SetHeaderRef:
      return {
        ...state,
        headerRef: action.payload
      };

    case PlayerActionTypes.SetMaxiPlayerRef:
      return {
        ...state,
        maxiPlayerRef: action.payload
      };

    case PlayerActionTypes.SetPlayerMaximised:
      return {
        ...state,
        isPlayerMaximised: action.payload
      };

    case PlayerActionTypes.SetFooterPlayerOpen:
      return {
        ...state,
        isFooterPlayerOpen: action.payload
      };

    case PlayerActionTypes.HandleMute:
      if (!action.payload.muteIt && state.volume !== 0) {
        return {
          ...state,
          preMutedVolume: state.volume,
          volume: 0,
          isMuted: true
        };
      }
      return {
        ...state,
        volume: state.preMutedVolume,
        isMuted: false
      };

    case PlayerActionTypes.Scrub:
      if (state.playerRef) {
        state.playerRef.seekTo(action.payload, "fraction");
      }
      return state;

    case PlayerActionTypes.Skip:
      if (!state.playerRef || !state.playerRef.getCurrentTime()) {
        return state;
      }
      const newTime = action.payload.reset
        ? 0
        : action.payload.direction === "back"
        ? state.playerRef.getCurrentTime() - action.payload.seconds
        : state.playerRef.getCurrentTime() + action.payload.seconds;

      state.playerRef.seekTo(newTime, "seconds");

      return state;

    case PlayerActionTypes.DismissPlayer:
      return {
        ...state,
        isPlaying: false,
        isFooterPlayerOpen: false,
        isPlayerMaximised: false,
        currentPlaying: null
      };

    case PlayerActionTypes.MaximisePlayer:
      return {
        ...state,
        isFooterPlayerOpen: false,
        isPlayerMaximised: true
      };

    case PlayerActionTypes.MinimisePlayer:
      return {
        ...state,
        isFooterPlayerOpen: true,
        isPlayerMaximised: false
      };

    case PlayerActionTypes.HandleEpisodePlay:
      return {
        ...state,
        isPlaying: action.payload.play,
        isReady: false,
        isFooterPlayerOpen: !state.isFooterPlayerOpen
          ? true
          : state.isFooterPlayerOpen
      };

    case PlayerActionTypes.HandleInlinePlayerPlay:
      return {
        ...state,
        isPlaying: Boolean(action.payload.forcePlay) ? true : !state.isPlaying,
        isReady: false,
        currentPlaying: action.payload.currentPlaying
      };

    case PlayerActionTypes.UpdateAvailableEpisodes:
      return {
        ...state,
        availableEpisodes: action.payload
      };

    // error on no action
    default:
      throw new Error();
  }
};
