import { CSSObject } from "@emotion/core";
import { variables } from "./variables";

interface TextStyles {
  headingXl: CSSObject;
  headingL: CSSObject;
  headingM: CSSObject;
  headingS: CSSObject;
  headingXs: CSSObject;
  bodyL: CSSObject;
  bodyM: CSSObject;
  bodyS: CSSObject;
  bodyXs: CSSObject;
}

export const fonts = {
  header: "Poppins",
  body: "Poppins"
};

export const fontWeights = {
  light: 100,
  medium: 200,
  default: 400,
  bold: 600,
  heavy: 700
};

const boldStyles: CSSObject = {
  fontWeight: fontWeights.heavy,
  lineHeight: "144.7%"
};

const baseSizing: CSSObject = {
  letterSpacing: "1px",
  lineHeight: "144.7%"
};

const baseMargins: CSSObject = {
  marginTop: 0,
  marginBottom: variables.spacing.medium
};

export const textStyles: TextStyles = {
  headingXl: {
    ...boldStyles,
    ...baseSizing,
    ...baseMargins,
    fontSize: "3.947rem" // 75
  },
  headingL: {
    ...boldStyles,
    ...baseSizing,
    ...baseMargins,
    fontSize: "2.947rem", // 56
    lineHeight: "125.2%"
  },
  headingM: {
    ...boldStyles,
    ...baseSizing,
    ...baseMargins,
    fontSize: "2.211rem" // 42
  },
  headingS: {
    ...boldStyles,
    ...baseSizing,
    ...baseMargins,
    fontSize: "1.632rem" // 31
  },
  headingXs: {
    ...boldStyles,
    ...baseSizing,
    ...baseMargins,
    fontSize: "1.421rem" // 27
  },
  bodyL: {
    ...baseSizing,
    fontSize: "1.263rem" // 24
  },
  bodyM: {
    ...baseSizing,
    fontSize: "1rem",
    lineHeight: "163.7%" // 19
  },
  bodyS: {
    ...baseSizing,
    fontSize: "0.8421rem", //16
    lineHeight: "163.7%"
  },
  bodyXs: {
    ...baseSizing,
    fontSize: "0.7368rem", // 14
    lineHeight: "125.2%"
  }
};

export const textStylesItalic: Pick<
  TextStyles,
  "headingXl" | "headingM" | "bodyL" | "bodyM"
> = {
  headingXl: {
    ...textStyles.headingXl,
    fontStyle: "italic"
  },
  headingM: {
    ...textStyles.headingM,
    fontStyle: "italic"
  },
  bodyL: {
    ...textStyles.bodyL,
    fontStyle: "italic"
  },
  bodyM: {
    ...textStyles.bodyM,
    fontStyle: "italic"
  }
};
