import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";
import { colors } from "../../styles/colors";
import { textStyles } from "../../styles/type";

export const styles: EmotionStyles = {
  button: {
    ...textStyles.bodyM,
    backgroundColor: colors.white,
    borderColor: colors.black,
    padding: `${variables.spacing.small} ${variables.spacing.medium}`,
    cursor: "pointer",
    borderRadius: 0,
    borderStyle: "solid",
    "&:focus:not(:focus-visible)": {
      outlineColor: colors.black,
      outlineStyle: "none"
    }
  },
  secondary: {
    backgroundColor: colors.primary,
    borderWidth: 1,
    borderColor: colors.primary,
    color: colors.black,
    "&:focus:not(:focus-visible)": {
      outlineColor: colors.black,
      outlineStyle: "none"
    }
  },
  asWrapper: {
    display: "inline-block !important"
  }
};
