import React, { createContext, useMemo, useReducer } from "react";

/**
 * Creates a react Context combined with useReducer.
 * @param reducer - reference to a React.Reducer function that returns an updated state
 * @param initialState - an initial state object to initialise the context
 */
export function createStore<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType
) {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState;
  const context = createContext({
    dispatch: defaultDispatch,
    state: initialState
  });
  function Provider(props: React.PropsWithChildren<{}>) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const contextValue = useMemo(() => {
      return { state, dispatch };
    }, [state, dispatch]);
    return <context.Provider value={contextValue} {...props} />;
  }
  return [context, Provider] as [typeof context, typeof Provider];
}
