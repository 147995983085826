import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";
import { breakpointMixins } from "../../styles/breakpoints";
import { colors } from "../../styles/colors";
import { textStyles } from "../../styles/type";
import { contentStyles } from "../../styles/content";
const { spacing } = variables;

export const styles: EmotionStyles = {
  container: {
    minHeight: "100%",
    backgroundColor: colors.blushPink,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: `${spacing.medium} 0`,
    paddingBottom: 160,
    [breakpointMixins.md]: {
      padding: `${spacing.large} 0`,
      paddingBottom: 80
    },
    [breakpointMixins.lg]: {
      paddingBottom: 160
    }
  },
  inner: {
    flex: 1,
    margin: `${spacing.medium} auto`,
    paddingTop: spacing.medium,
    [breakpointMixins.lg]: {
      margin: `${spacing.large} auto`
    }
  },
  box: {
    backgroundColor: colors.white,
    padding: spacing.medium,
    [breakpointMixins.lg]: {
      padding: `${spacing.large}`
    }
  },
  grid: {
    [breakpointMixins.md]: {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  heading: {
    marginTop: 0,
    textTransform: "uppercase",
    ...textStyles.headingXs,
    [breakpointMixins.lg]: {
      ...textStyles.headingS
    }
  },
  playerTime: {
    margin: `${spacing.medium} 0`
  },
  description: {
    ...contentStyles,
    margin: `${spacing.medium} 0 ${spacing.large}`,
    ...textStyles.bodyM
  },
  image: {
    display: "none",
    [breakpointMixins.md]: {
      display: "block",
      maxWidh: 200,
      marginRight: spacing.large
    }
  },
  closeIcons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: spacing.large
  }
};
