import { HeadingLevel } from "../components/Heading";

export const incrementHeadingLevel = (
  level: HeadingLevel,
  increment: number
): HeadingLevel => {
  const currentLevel = parseInt(level.charAt(1));
  if (isNaN(currentLevel)) {
    return "h1";
  }
  const newLevel = currentLevel + increment;
  if (newLevel > 5) {
    return "h5";
  }
  if (newLevel < 1) {
    return "h1";
  }
  return `h${newLevel}` as HeadingLevel;
};
