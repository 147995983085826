import { CSSObject } from "@emotion/core";
import { baseColors } from "./colors";

const rawSpacing = {
  xSmall: 0.25,
  small: 0.5,
  medium: 1,
  large: 2
};

const maxWidth = 1120;

export const variables = {
  spacing: {
    xSmall: `${rawSpacing.xSmall}rem`,
    small: `${rawSpacing.small}rem`,
    medium: `${rawSpacing.medium}rem`,
    large: `${rawSpacing.large}rem`,
    xLarge: `${rawSpacing.large * 2}rem`,
    raw: {
      ...rawSpacing
    }
  },
  centeredContainer: {
    maxWidth,
    margin: "0 auto"
  } as CSSObject,
  contentWidth: 760,
  maxWidth,
  borderWidth: "3px",
  colorTransition: "color 0.3s ease",
  boxShadow: `-3px 3px 6px -2px ${baseColors.grey}`
};
