import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { ChildImageSharp } from "../../../interfaces/images";

import { imageTileStyles as styles } from "../styles";

interface EpisodeImageTileProps {
  season: number;
  image: ChildImageSharp
}

const EpisodeImageTile: React.FC<EpisodeImageTileProps> = ({ season, image }) => {
  const data: {
    season1: ChildImageSharp;
    season2: ChildImageSharp;
  } = useStaticQuery(graphql`
    query {
      season1: file(relativePath: { eq: "wmdj_series_1_tile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      season2: file(relativePath: { eq: "wmdj_series_2_tile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  
  const seasonImage = season === 1 ? "season1" : "season2";
  return (
    <div css={styles.container}>
      <Img fluid={image ? image.childImageSharp.fluid : data[seasonImage].childImageSharp.fluid} />
    </div>
  );
};

export default EpisodeImageTile;
