import React from "react";

import { playbackRateButtonStyles as styles } from "../styles";
import { PlaybackRateButtonProps } from "../props";

const PlaybackRateButton: React.FC<PlaybackRateButtonProps> = ({
  handleOnClick,
  rate
}) => {
  return (
    <button css={[styles.button]} onClick={handleOnClick}>
      x{rate}
    </button>
  );
};

export default PlaybackRateButton;
