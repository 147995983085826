import { Link } from "gatsby";
import React, { RefObject } from "react";
import setTestId from "../../../helpers/setTestId";
import { SanityNavItem } from "../../Header";
import Inner from "../../Inner";
import { footerStyles as styles } from "../footerStyles";
import SocialLinks from "../../SocialLinks";

interface BasicFooterProps {
  footerRef: RefObject<HTMLElement> | null;
  footerNav: Array<SanityNavItem>;
}

const BasicFooter: React.FC<BasicFooterProps> = ({ footerRef, footerNav }) => {
  return (
    <footer
      css={styles.container}
      ref={footerRef}
      {...setTestId("basic-footer")}
    >
      <Inner cssStyles={[styles.inner]}>
        <div css={styles.copyright}>
          &copy; All content copyright of Why Mums Don't Jump
        </div>
        <div>
          <div css={styles.footerNavContainer}>
            <div css={styles.footerNav}>
              {footerNav &&
                footerNav.map(({ _key, path, label }) => (
                  <Link key={_key} to={path} css={styles.footerLink}>
                    {label}
                  </Link>
                ))}
            </div>
            <SocialLinks />
          </div>
          <a
            href="https://podcasts.apple.com/gb/podcast/why-mums-dont-jump/id1507937343"
            css={[styles.footerLink, styles.footerLinkLast]}
            aria-label="Find Why Mums Don't Jump on Apple Podcasts"
            target="_blank"
          >
            <img alt="Apple podcasts badge" src="/apple-badge.svg" />
          </a>
        </div>
      </Inner>
    </footer>
  );
};

export default BasicFooter;
