import React, { Fragment, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import FooterPlayer from "../FooterPlayer";
import { SanityNavItem } from "../Header";
import BasicFooter from "./components/BasicFooter";

export interface FooterProps {
  footerNav: Array<SanityNavItem>;
}

function Footer({ footerNav }: FooterProps) {
  const {
    state: { currentPlaying, headerRef }
  } = usePlayer();
  const footerRef = useRef<HTMLElement>(null);
  const { ref, inView } = useInView({
    threshold: 0.64
  });
  return (
    <Fragment>
      <div ref={ref}>
        <BasicFooter footerRef={footerRef} footerNav={footerNav} />
      </div>
      {currentPlaying && (
        <FooterPlayer
          bottomPos={
            inView && footerRef.current
              ? footerRef.current.getBoundingClientRect().height
              : 0
          }
          headerBottom={headerRef?.getBoundingClientRect().height}
        />
      )}
    </Fragment>
  );
}

export default Footer;
