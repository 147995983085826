import ReactGA from "react-ga";
import { CurrentPlaying } from "../interfaces/podcasts";

const shouldTrack = () => {
  return (
    process.env.NODE_ENV === "production" ||
    process.env.ALLOW_TEST_TRACKING === "true"
  );
};

export const trackCustomEvent = (args: ReactGA.EventArgs) => {
  if (shouldTrack()) {
    return ReactGA.event(args);
  }
};

export const trackPlayButtonEvent = (
  val: boolean,
  currentPlaying: CurrentPlaying | null
) => {
  if (shouldTrack()) {
    trackCustomEvent({
      category: `${
        currentPlaying ? currentPlaying.episode.attributes.title : "First"
      } Play Button`,
      action: val ? "play" : "pause",
      label: "Podcast Player Interaction"
    });
  }
};

export const trackSearchQuery = (successful: boolean, queryString: string) => {
  if (shouldTrack()) {
    trackCustomEvent({
      category: `${successful ? "Successful" : "Unsuccessful"} Search Query`,
      action: queryString,
      label: "Search Query"
    });
  }
};

export const trackBuyMeACoffee = () => {
  if (shouldTrack()) {
    trackCustomEvent({
      category: "Buy Me A Coffee",
      action: "click",
      label: "Buy Me A Coffee button clicked",
      value: 1
    });
  }
};

export const trackSeriesTab = (action: string) => {
  if (shouldTrack()) {
    trackCustomEvent({
      category: "Series tab interaction",
      action,
      label: "Series tab click"
    });
  }
};

export const trackProductClick = (product: string, brand: string) => {
  if (shouldTrack()) {
    trackCustomEvent({
      category: "Shop",
      action: `${product} CTA clicked`,
      label: `Product click`,
      value: 1
    });
  }
};

export const trackCategoryFilter = (category: string) => {
  if (shouldTrack()) {
    trackCustomEvent({
      category: "Shop",
      action: `Category filter clicked: ${category}`,
      label: "Category filter click"
    });
  }
};
