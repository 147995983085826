import { EmotionStyles } from "../../../interfaces/emotion";
import { variables } from "../../../styles/variables";
import { colors, baseColors } from "../../../styles/colors";
import { fonts, textStyles } from "../../../styles/type";
import { breakpointMixins } from "../../../styles/breakpoints";
import { keyframes } from "@emotion/core";

const mobileNavHiddenPosition = 500;
const slideIn = keyframes`
  0% {
    left: ${mobileNavHiddenPosition}px
  }
  100% {
    left: 0
  }
`;

const { spacing, colorTransition, borderWidth } = variables;

export const styles: EmotionStyles = {
  nav: {
    top: 0,
    left: "auto",
    margin: 0,
    overflow: "hidden",

    [breakpointMixins.md]: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      overflow: "visible"
    },

    "& a": {
      textDecoration: "none",
      transition: `${colorTransition} !important`,
      color: baseColors.black
    },

    "& a:hover": {
      color: colors.pinkDark
    },

    "& li": {
      [breakpointMixins.md]: {
        display: "inline-block"
      }
    }
  },

  list: {
    listStyleType: "none",
    alignItems: "center",
    padding: 0,
    animation: `${slideIn} 0.5s ease-in-out forwards`,
    position: "relative",
    top: 0,
    left: mobileNavHiddenPosition,
    height: "100%",
    backgroundColor: baseColors.white,

    [breakpointMixins.md]: {
      position: "static",
      left: 0,
      backgroundColour: "transparent",

      "& ul": {
        animation: "none"
      },

      "& ul li": {
        display: "block"
      }
    }
  },

  navOpen: {
    position: "absolute",
    display: "block",
    width: "100vw",
    right: 0,
    height: "100vh",
    zIndex: 50,

    "& ul": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      margin: 0,
      paddingTop: 120,
      backgroundColor: baseColors.white,
      borderLeft: `${borderWidth} solid ${colors.lightGrey}`
    },
    "& li": {
      ...textStyles.bodyM,
      padding: `${spacing.medium} ${spacing.medium} ${spacing.medium} 0`
    },
    "& a": {
      color: colors.black
    }
  },

  navClosed: {
    display: "none",
    height: "inherit"
  },

  navTrigger: {
    [breakpointMixins.md]: {
      display: "none !important"
    }
  },

  navTriggerClose: {
    position: "absolute",
    top: "3rem",
    right: spacing.medium,
    padding: spacing.medium
  },

  navItem: {
    marginRight: spacing.medium,
    fontFamily: fonts.header
  },

  mobileNav: {
    display: "flex",
    marginTop: spacing.small,
    [breakpointMixins.md]: {
      display: "none"
    }
  },

  mobileSearch: {
    [breakpointMixins.md]: {
      display: "none !important"
    }
  },

  mobileSocial: {
    marginRight: spacing.medium
  },

  openIcon: {
    margin: `0 0.25rem ${spacing.small} auto`,
    zIndex: 100
  },

  menuText: {
    display: "block",
    ...textStyles.bodyXs,
    textTransform: "uppercase",
    color: colors.black
  },

  button: {
    marginTop: spacing.medium,
    textAlign: "center",
    position: "relative",
    "& a:hover": {
      borderBottom: "none"
    },

    "& a.wmdj-nav-active": {
      borderBottom: "none"
    }
  },

  desktopSocial: {
    display: "none",

    [breakpointMixins.md]: {
      display: "block"
    }
  }
};
