import { Link } from "gatsby";
import React from "react";
import { getTranscriptPath } from "../../helpers/getTranscriptPath";
import { TransistorEpisodeInterface } from "../../interfaces/podcasts";

interface SeriesLinkProps {
  episode: TransistorEpisodeInterface;
  isTranscript?: boolean;
  showTranscriptLink?: boolean;
  onLinkClick?: () => void;
}

const SeriesLink = ({
  isTranscript,
  showTranscriptLink,
  episode,
  onLinkClick
}: SeriesLinkProps) => {
  return (
    <>
      <p>
        This episode is from Series {episode.attributes.season} of{" "}
        <Link to="/" onClick={onLinkClick}>
          Why Mums Don't Jump
        </Link>
      </p>
      {!isTranscript && showTranscriptLink && (
        <p>
          <Link to={getTranscriptPath(episode)} onClick={onLinkClick}>
            Find a full transcript here.
          </Link>
        </p>
      )}
      <p>
        This podcast is not intended to be a substitute for professional medical
        advice.
      </p>
    </>
  );
};

export default SeriesLink;
