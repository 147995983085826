import {
  SlimTransistorEpisode,
  TransistorEpisodeInterface
} from "../interfaces/podcasts";
import { prettifySlug } from "./prettifySlug";

export const getEpisodePath = (
  episode: TransistorEpisodeInterface | SlimTransistorEpisode
) => {
  const { season, title } = episode.attributes;
  return `/episodes/series-${season}/${prettifySlug(title)}`;
};
