import {
  PlayerActionTypes,
  PlayerStoreAction
} from "../../../contexts/PlayerContextV2/interfaces/actions";

type DispatchHelper = (
  dispatch: React.Dispatch<PlayerStoreAction>,
  play?: boolean
) => void;

export const handleDismissPlayer: DispatchHelper = (dispatch) => {
  dispatch({
    type: PlayerActionTypes.DismissPlayer
  });
};

export const handleMaximisePlayer: DispatchHelper = (dispatch) => {
  dispatch({
    type: PlayerActionTypes.MaximisePlayer
  });
};

export const handleMinimizePlayer: DispatchHelper = (dispatch) => {
  dispatch({
    type: PlayerActionTypes.MinimisePlayer
  });
};

export const handleEpisodePlay: DispatchHelper = (dispatch, play) => {
  dispatch({
    type: PlayerActionTypes.HandleEpisodePlay,
    payload: {
      play: Boolean(play)
    }
  });
};

export const handleSetPlaying: DispatchHelper = (dispatch, play) => {
  dispatch({
    type: PlayerActionTypes.SetPlayingState,
    payload: {
      field: "isPlaying",
      value: Boolean(play)
    }
  });
};
