import React, { useEffect, useState } from "react";
import { IoCloseOutline as CloseIcon } from "@react-icons/all-files/io5/IoCloseOutline";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import useSessionStorage from "../../hooks/useSessionStorage";
import Figure from "../Figure";
import { styles } from "./styles";
import { CMSImageBanner } from "../Layout";
import { Link } from "gatsby";

const ImageBanner: React.FC<CMSImageBanner> = ({
  bannerImage,
  id,
  dismissedTab
}) => {
  const [isShowing, setIsShowing] = useState(false);
  const [isDismissed, setIsDismissed] = useSessionStorage(
    `isBannerDismissed_${id}`,
    false
  );
  const {
    state: { isPlayerMaximised }
  } = usePlayer();

  useEffect(() => {
    setTimeout(() => {
      if (isDismissed || isPlayerMaximised) {
        setIsShowing(false);
      } else {
        setIsShowing(true);
      }
    }, 100);
  }, [isDismissed, isPlayerMaximised]);

  return (
    <>
      <aside
        css={{
          ...styles.banner,
          ...styles[`${isShowing ? "bannerShown" : "bannerDismissed"}`]
        }}
        title="Why Mums Don't Jump Book"
      >
        <CloseIcon
          css={styles.bannerButton}
          onClick={() => setIsDismissed(true)}
        />
        <div css={styles.bannerInner}>
          <Link
            to="/book"
            onClick={() => setIsDismissed(true)}
            aria-label="Find out more about the Why Mums Don't Jump Book"
          >
            <Figure node={bannerImage} styles={styles.bannerImage} />
          </Link>
        </div>
      </aside>
      {isDismissed && (
        <aside css={styles.tab} title="Why Mums Don't Jump Book">
          <Link
            to="/book"
            aria-label="Find out more about the Why Mums Don't Jump Book"
          >
            {dismissedTab}
          </Link>
        </aside>
      )}
    </>
  );
};

export default ImageBanner;
