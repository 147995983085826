import React from "react";
import { getEpisodePath } from "../../../helpers/getEpisodePath";
import { getTranscript } from "../../../helpers/getTranscript";
import { getTranscriptPath } from "../../../helpers/getTranscriptPath";
import { hasTranscript } from "../../../helpers/hasTranscript";
import { TransistorEpisodeInterface } from "../../../interfaces/podcasts";
import { Transcript } from "../../../interfaces/transcript";
import TabButtons from "../../TabButtons";

interface TranscriptTabsProps {
  activeTab: "episode" | "transcript";
  episode: TransistorEpisodeInterface;
  transcripts?: Transcript[];
  onClick?: (value: string) => void;
}

const TranscriptTabs: React.FC<TranscriptTabsProps> = ({
  activeTab,
  episode,
  transcripts = []
}) => {
  const { title } = episode.attributes;
  const episodePath = getEpisodePath(episode);
  const transcriptPath = getTranscriptPath(episode);
  if (
    !hasTranscript(title, transcripts) ||
    !getTranscript(title, transcripts)?.createTranscriptPage
  ) {
    return null;
  }
  return (
    <TabButtons
      buttons={[
        {
          description: "Show notes",
          type: activeTab === "episode" ? "primary" : "secondary",
          linkUrl: episodePath,
          isActive: activeTab === "episode"
        },
        {
          description: "Transcript",
          type: activeTab === "transcript" ? "primary" : "secondary",
          linkUrl: transcriptPath,
          isActive: activeTab === "transcript"
        }
      ]}
    />
  );
};

export default TranscriptTabs;
