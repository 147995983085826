import React from "react";
import { CookiesProvider } from "react-cookie";
import { Global } from "@emotion/core";
import { globalStyles } from "../src/styles/globalStyles";

export function onClientEntry() {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (
    typeof window !== "undefined" &&
    typeof window.IntersectionObserver === `undefined`
  ) {
    require(`intersection-observer`);
    console.log(`👍 IntersectionObserver is polyfilled`);
  }
}

export const wrapRootElement = ({ element }) => {
  const isDevelopment = process.env.NODE_ENV === "development";

  return (
    <CookiesProvider>
      {isDevelopment && <Global styles={globalStyles} />}
      {element}
    </CookiesProvider>
  );
};
