import React, { Fragment } from "react";
import { Link } from "gatsby";

import Heading, { HeadingLevel } from "../Heading";
import Inner from "../Inner";
import EpisodeImageTile from "../Episode/components/EpisodeImageTile";
import IconsBar from "../Episode/components/IconsBar";
import InlinePlayer from "../Player/components/InlinePlayer.tsx";
import { TransistorEpisodeInterface } from "../../interfaces/podcasts";
import TimeElapsed from "../Player/components/TimeElapsed";
import MinimizeDismissControls, {
  MinimizeDismissControlsProps
} from "../MinimizeDismissControls";

import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import { styles } from "./styles";
import BuyMeACoffee from "../BuyMeACoffee";
import { Transcript } from "../../interfaces/transcript";
import TranscriptTabs from "../TranscriptPage/components/TranscriptPageTabs";
import { hasTranscript } from "../../helpers/hasTranscript";
import { getTranscript } from "../../helpers/getTranscript";
import { getTranscriptPath } from "../../helpers/getTranscriptPath";
import { incrementHeadingLevel } from "../../helpers/incrementHeadingLevel";
import { CSSObject } from "@emotion/core";
import SeriesLink from "../SeriesLink";

interface EpisodePageProps extends MinimizeDismissControlsProps {
  episode: TransistorEpisodeInterface;
  transcripts?: Array<Transcript>;
  fromFooter?: boolean;
  heading?: string;
  headingLevel?: HeadingLevel;
  headingStyles?: CSSObject;
  includeBuyMeACoffee?: boolean;
}

const EpisodePage: React.FC<EpisodePageProps> = ({
  episode,
  onMinimize,
  onDismiss,
  transcripts,
  fromFooter,
  heading,
  headingLevel = "h2",
  headingStyles,
  includeBuyMeACoffee = true
}) => {
  const {
    image,
    attributes: {
      title,
      media_url,
      description,
      formatted_summary,
      season,
      duration_in_mmss
    }
  } = episode;
  const {
    state: { playedSeconds, currentPlaying }
  } = usePlayer();

  return (
    <div css={styles.container}>
      {heading && (
        <Heading headingLevel={headingLevel} styles={headingStyles ?? {}}>
          {heading}
        </Heading>
      )}
      <Inner cssStyles={[styles.inner]}>
        <div css={styles.box}>
          <MinimizeDismissControls
            onMinimize={onMinimize}
            onDismiss={onDismiss}
            containerStyles={styles.closeIcons}
          />
          <div css={styles.grid}>
            <div css={styles.image}>
              <EpisodeImageTile season={season} image={image} />
            </div>
            <div>
              <Heading
                headingLevel={
                  heading ? incrementHeadingLevel(headingLevel, 1) : "h1"
                }
                styles={styles.heading}
                italic={false}
              >
                {title}
              </Heading>
              <InlinePlayer episode={episode} />
              <div>
                <div css={styles.playerTime}>
                  <TimeElapsed
                    elapsed={
                      currentPlaying && currentPlaying.episode.id === episode.id
                        ? playedSeconds
                        : "00:00"
                    }
                    totalDuration={duration_in_mmss}
                  />
                </div>
              </div>
              {!fromFooter && (
                <TranscriptTabs
                  episode={episode}
                  activeTab="episode"
                  transcripts={transcripts}
                />
              )}
              <div css={styles.description}>
                {description ? (
                  <Fragment>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                    <br />
                    <SeriesLink
                      onLinkClick={onMinimize}
                      episode={episode}
                      isTranscript={
                        hasTranscript(
                          episode.attributes.title,
                          transcripts ?? []
                        ) &&
                        getTranscript(
                          episode.attributes.title,
                          transcripts ?? []
                        )?.createTranscriptPage
                      }
                      showTranscriptLink={fromFooter}
                    />
                    {includeBuyMeACoffee && <BuyMeACoffee />}
                  </Fragment>
                ) : (
                  <div>
                    {formatted_summary}
                    <SeriesLink
                      onLinkClick={onMinimize}
                      episode={episode}
                      isTranscript={
                        hasTranscript(
                          episode.attributes.title,
                          transcripts ?? []
                        ) &&
                        getTranscript(
                          episode.attributes.title,
                          transcripts ?? []
                        )?.createTranscriptPage
                      }
                      showTranscriptLink={fromFooter}
                    />
                    {includeBuyMeACoffee && <BuyMeACoffee />}
                  </div>
                )}
                <IconsBar
                  mediaUrl={media_url}
                  title={title}
                  downloadText="Download episode"
                />
              </div>
            </div>
          </div>
        </div>
      </Inner>
    </div>
  );
};

export default EpisodePage;
