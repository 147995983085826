/** @jsx jsx */
import { jsx } from "@emotion/react";
import { CSSObject } from "@emotion/core";
import React from "react";
import { getHeadingStyles } from "./headingStyles";

export type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5";
interface HeadingProps {
  headingLevel: HeadingLevel;
  styles?: CSSObject;
  onClick?: () => void;
  italic?: boolean;
  id?: string;
}

const Heading: React.FC<HeadingProps> = ({
  headingLevel,
  onClick,
  styles,
  children,
  italic = true,
  id
}) => {
  const element = jsx(headingLevel, {
    children,
    css: { ...getHeadingStyles(headingLevel, italic), ...styles },
    onClick,
    id
  });
  return element;
};

export default Heading;
