import hexToRgba from "hex-to-rgba";
import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { colors } from "../../styles/colors";
import { textStyles } from "../../styles/type";
import { variables } from "../../styles/variables";

const { spacing, maxWidth } = variables;

export const styles: EmotionStyles = {
  container: {
    width: "100%",
    backgroundColor: hexToRgba(colors.primary, 0.95),
    position: "fixed",
    left: 0,
    bottom: 0,
    ...textStyles.bodyXs,
    fontSize: "0.6316rem",

    [breakpointMixins.sm]: {
      fontSize: textStyles.bodyXs.fontSize
    }


  },
  bar: {
    padding: spacing.medium,
    maxWidth,
    margin: "0 auto"
  },
  inner: {
    maxWidth,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    [breakpointMixins.lg]: {
      display: "grid",
      gridGap: spacing.medium,
      gridTemplateColumns: "minMax(450px, 650px) auto"
    }
  },
  buttonsContainer: {
    textAlign: "right"
  },
  prefsLink: {
    display: "block",
    marginTop: spacing.medium,
    [breakpointMixins.lg]: {
      marginTop: 25
    }
  }
};
