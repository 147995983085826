import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import { faUndo } from "@fortawesome/free-solid-svg-icons/faUndo";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";
import { skipButtonsStyles as styles } from "../styles";
import { SkipButtonsProps } from "../props";

const SkipButtons: React.FC<SkipButtonsProps> = ({
  handleClick,
  backValue,
  forwardValue,
  children
}) => {
  const [backClicked, setBackClicked] = useState(false);
  const [forwardClicked, setForwardClicked] = useState(false);

  useEffect(() => {
    if (forwardClicked || backClicked) {
      const timeout = setTimeout(() => {
        setBackClicked(false);
        setForwardClicked(false);
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [forwardClicked, backClicked]);

  return (
    <div css={styles.buttonContainer}>
      <button
        css={[styles.button, styles.back]}
        onClick={() => {
          setBackClicked(true);
          handleClick({
            direction: "back",
            seconds: backValue || 10
          });
        }}
        name="skipBack"
        aria-label="skip back"
      >
        <FontAwesomeIcon
          icon={backClicked ? faSync : faUndo}
          css={styles.icon}
          spin={backClicked}
        />
      </button>
      {children}
      <button
        css={[styles.button, styles.forward]}
        onClick={() => {
          setForwardClicked(true);

          handleClick({
            direction: "forward",
            seconds: forwardValue || 30
          });
        }}
        name="skipForwards"
        aria-label="skip forwards"

      >
        <FontAwesomeIcon
          icon={forwardClicked ? faSync : faRedo}
          css={styles.icon}
          spin={forwardClicked}
        />
      </button>
    </div>
  );
};

export default SkipButtons;
