import { CSSObject } from "@emotion/core";
import { HeadingLevel } from ".";
import { textStyles } from "../../styles/type";

type GetHeadingStyles = (level: HeadingLevel, italic: boolean) => CSSObject;

export const getHeadingStyles: GetHeadingStyles = (level, italic) => {
  switch (level) {
    case "h1":
      return italic
        ? { ...textStyles.headingL, fontStyle: "italic" }
        : textStyles.headingL;
    case "h2":
      return italic
        ? { ...textStyles.headingS, fontStyle: "italic" }
        : textStyles.headingS;
    case "h3":
    default:
      return italic
        ? { ...textStyles.headingXs, fontStyle: "italic" }
        : textStyles.headingXs;
  }
};
