import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";

import Nav from "../Nav";

import { navItems as backupNavItems } from "../../navigation/navItems";
import { headerStyles as styles } from "./styles";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import { PlayerActionTypes } from "../../contexts/PlayerContextV2/interfaces/actions";

export interface SanityNavItem {
  path: string;
  label: string;
  _key: string;
}
export interface HeaderProps {
  siteTitle: string;
  navItems: Array<SanityNavItem>;
}

const Header: React.FC<HeaderProps> = ({ siteTitle, navItems }) => {
  const { dispatch } = usePlayer();
  const headerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (headerRef.current) {
      dispatch({
        type: PlayerActionTypes.SetHeaderRef,
        payload: headerRef.current
      });
    }
  }, [headerRef.current]);
  return (
    <>
      <div css={styles.headerContainer} ref={headerRef}>
        <header css={styles.header}>
          <div css={styles.logoContainer}>
            <Link css={styles.titleLink} to="/" title={`${siteTitle} Home`}>
              <span css={styles.title}>WMDJ</span>
            </Link>
          </div>
          <Nav navItems={navItems || backupNavItems} />
        </header>
      </div>
    </>
  );
};

export default Header;
