import { colors } from "../../../styles/colors";
import { variables } from "../../../styles/variables";
import { EmotionStyles } from "../../../interfaces/emotion";

export const footerStyles: EmotionStyles = {
  container: {
    position: "fixed",
    left: 0,
    width: "100vw",
    background: colors.footer,
    borderTop: `1px solid ${colors.lightGrey}`
  },
  containerMaximised: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: colors.blushPink,
    borderTopWidth: 0,
    overflowY: "auto",
    zIndex: 500
  },
  inner: {
    maxWidth: variables.maxWidth,
    margin: "0 auto"
  },
  maxiPlayerContainer: {
    overflowY: "auto"
  },
  footerContainer: {}
};
