import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";

export const styles: EmotionStyles = {
  content: {
    paddingBottom: 140,
    flex: 1,
    [breakpointMixins.md]: {
      paddingBottom: 80
    }
  },
  noFooter: {
    paddingBottom: 0
  },
  fullPage: {
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    [breakpointMixins.md]: {
      paddingBottom: 0
    }
  }
};
