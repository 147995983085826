import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";
import { breakpointMixins } from "../../styles/breakpoints";

export const styles: EmotionStyles = {
  container: {
    display: "flex",
    fontSize: "1.25rem",
    textAlign: "center",

    [breakpointMixins.md]: {
      display: "block",
      paddingLeft: variables.spacing.medium
    }
  },
  iconLink: {
    display: "inline-block"
  },
  left: {
    "& a": {
      marginRight: variables.spacing.small
    },
    [breakpointMixins.md]: {
      textAlign: "left"
    }
  },
  right: {
    "& a": {
      marginLeft: variables.spacing.small
    },
    [breakpointMixins.md]: {
      textAlign: "right"
    }
  },
  searchIcon: {
    display: "none",
    [breakpointMixins.sm]: {
      display: "inline-block"
    },

    [breakpointMixins.md]: {
      marginLeft: variables.spacing.medium
    }
  }
};
