import React from "react";
import { CSSObject } from "@emotion/core";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { CgClose } from "@react-icons/all-files/cg/CgClose";

export interface MinimizeDismissControlsProps {
  onMinimize?: () => void;
  onDismiss?: () => void;
  onMaximize?: () => void;
  containerStyles?: CSSObject;
}

const MinimizeDismissControls: React.FC<MinimizeDismissControlsProps> = ({
  onMinimize,
  onMaximize,
  onDismiss,
  containerStyles
}) => {
  return (
    <div css={containerStyles}>
      {onMaximize && <BsChevronUp onClick={onMaximize} />}
      {onMinimize && <BsChevronDown onClick={onMinimize} />}
      {onDismiss && <CgClose onClick={onDismiss} style={{ marginLeft: 8 }} />}
    </div>
  );
};

export default MinimizeDismissControls;
