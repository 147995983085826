module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-184635566-1","cookieName":"wmdjPc"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Why Mums Don't Jump","short_name":"WMDJ","start_url":"/","display":"minimal-ui","icon":"src/images/wmdj_icon.png"},
    },{
      plugin: require('../plugins/gatsby-source-transistorfm-api/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"mPzqGYChfE9gzSYZNmakeA"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/web/src/components/Layout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"gatsby","projectRoot":"/opt/build/repo/web","babel":{"plugins":["/opt/build/repo/web/node_modules/babel-plugin-remove-graphql-queries/index.js"]}},
    }]
