import { useLayoutEffect } from "react";
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
  BodyScrollOptions,
  enableBodyScroll
} from "body-scroll-lock";
import useIsMounted from "ismounted";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";

export const useFooterPlayer = () => {
  const {
    state: { maxiPlayerRef, isFooterPlayerOpen, isPlayerMaximised }
  } = usePlayer();
  const mounted = useIsMounted();

  const bodyScrollOptions: BodyScrollOptions = {
    reserveScrollBarGap: true
  };

  const handleOpen = () => {
    if (mounted) {
      window.scrollTo(0, 0);
    }
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    if (isPlayerMaximised && maxiPlayerRef) {
      disableBodyScroll(maxiPlayerRef, bodyScrollOptions);
      document.body.classList.add("navOpen");
    }
  };

  const handleClose = () => {
    document.getElementsByTagName("html")[0].style.overflow = "auto";
    if (maxiPlayerRef) {
      enableBodyScroll(maxiPlayerRef);
      document.body.classList.remove("navOpen");
    }
  };

  useLayoutEffect(() => {
    isPlayerMaximised ? handleOpen() : handleClose();

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isFooterPlayerOpen, isPlayerMaximised]);

  return { handleClose };
};
