import { EmotionStyles } from "../../interfaces/emotion";
import { colors } from "../../styles/colors";
import { variables } from "../../styles/variables";

export const styles: EmotionStyles = {
  skipLink: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    padding: `${variables.spacing.small} 0`,
    background: colors.coralPink,
    textAlign: "center",
    translate: "0 -100%",
    transition: "translate 150ms ease-in-out",
    zIndex: 1000,
    "&:focus": {
      top: 0,
      left: 0,
      translate: 0,
      outline: "none",
    }
    
  }
}