import React from "react";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { SiApplepodcasts as ApplePodcastsIcon } from "@react-icons/all-files/si/SiApplepodcasts";
import { SocialLinksProps } from "./props";
import { styles } from "./styles";
import { socialLinks } from "../../navigation/socialLinks";

const SocialLinks: React.FC<SocialLinksProps> = ({ align }) => {
  const alignment = align || "left";
  const { instagram, twitter, facebook, applePodcasts } = socialLinks;

  const iconMap: Array<{
    title: string;
    icon: JSX.Element;
    link?: string;
  }> = [
      { title: "Facebook", icon: <FaFacebook />, link: facebook },
      { title: "Twitter", icon: <FaTwitter />, link: twitter },
      { title: "Instagram", icon: <FaInstagram />, link: instagram },
      {
        title: "Apple Podcasts",
        icon: <ApplePodcastsIcon aria-label="WMDJ on Apple Podcasts" />,
        link: applePodcasts
      }
    ];

  return (
    <div css={[styles.container, styles[alignment]]}>
      {iconMap
        .filter(object => typeof object.link !== "undefined")
        .map(({ link, title, icon }) => {
          return (
            <a
              href={link}
              target="_blank"
              title={`WMDJ on ${title}`}
              key={title}
              css={styles.iconLink}
            >
              {icon}
            </a>
          );
        })}
    </div>
  );
};

export default SocialLinks;
