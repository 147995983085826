import { CSSObject } from "@emotion/core";
import { EmotionStyles } from "../../../interfaces/emotion";
import { breakpointMixins } from "../../../styles/breakpoints";
import { textStyles } from "../../../styles/type";
import { variables } from "../../../styles/variables";

const centered: CSSObject = {
  display: "flex",
  alignItems: "center"
};

export const playerMinimisedStyles: EmotionStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridRowGap: variables.spacing.small,
    padding: variables.spacing.small,
    maxWidth: "100vw",
    [breakpointMixins.md]: {
      padding: `${variables.spacing.small} 0`,
      gridColumnGap: variables.spacing.medium,
      gridRowGap: variables.spacing.small
    },
    [breakpointMixins.lg]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  icon: { marginLeft: 8 },
  header: {
    fontSize: "0.9474rem",
    gridColumn: "span 12",
    margin: 0,
    textTransform: "uppercase",
    order: 2,
    [breakpointMixins.md]: {
      order: 0,
      ...textStyles.headingXs,
      marginBottom: 0,
      fontSize: "0.9474rem",
      gridColumn: "span 7"
    },
    [breakpointMixins.lg]: {
      fontSize: "1rem"
    }
  },
  controls: {
    order: 5,
    gridColumn: "span 4",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: variables.spacing.small,
    [breakpointMixins.md]: {
      display: "block",
      order: 0,
      gridColumn: "span 2",
      padding: 0
    },
    [breakpointMixins.lg]: {
      gridColumn: "span 3"
    }
  },
  time: {
    gridColumn: "span 4",
    ...centered,
    order: 3,

    [breakpointMixins.md]: {
      gridColumn: "span 2",
      order: 4,
      textAlign: "center"
    },
    [breakpointMixins.lg]: {
      order: 0
    }
  },
  scrubber: {
    display: "none",
    [breakpointMixins.md]: {
      display: "block",
      gridColumn: "span 7",
      order: 5
    },
    [breakpointMixins.lg]: {
      order: 0,
      height: 10,
      width: "100%",
      maxWidth: 150
    }
  },
  soundStateIcons: {
    ...centered,
    order: 6,
    gridColumn: "span 3",
    justifyContent: "flex-end",
    [breakpointMixins.md]: {
      marginRight: variables.spacing.large
    },
    [breakpointMixins.lg]: {
      order: 0
    }
  },
  displayStateIcons: {
    ...centered,
    justifyContent: "flex-end",
    gridColumn: "span 12",
    marginRight: variables.spacing.small,
    [breakpointMixins.md]: {
      gridColumn: "span 3",
      order: 3,
      alignItems: "flex-start",
      marginRight: variables.spacing.large
    },
    [breakpointMixins.lg]: {
      gridColumn: "span 2",
      order: 0,
      marginRight: 0
    }
  }
};
