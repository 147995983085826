import React from "react";

import { styles } from "./styles";

const Content: React.FC<{ noFooter?: boolean; fullPage?: boolean, id?: string }> = ({
  children,
  noFooter,
  fullPage,
  id
}) => {
  return (
    <main
      css={[
        styles.content,
        noFooter ? styles.noFooter : undefined,
        fullPage && styles.fullPage
      ]}
      id={id ?? "mainContent"}
    >
      {children}
    </main>
  );
};

export default Content;
