import { EmotionStyles } from "../../interfaces/emotion";
// import { breakpointMixins } from "../../../styles/breakpoints";
// import { colors } from "../../../styles/colors";
import { variables } from "../../styles/variables";

const { spacing } = variables;

export const styles: EmotionStyles = {
  tabButtonContainer: {
    "& button, span": {
      marginRight: spacing.small
    }
  }
};
