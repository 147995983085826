import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { colors } from "../../styles/colors";
import { textStyles } from "../../styles/type";
import { variables } from "../../styles/variables";

export const footerStyles: EmotionStyles = {
  container: {
    backgroundColor: colors.black,
    ...textStyles.bodyXs,
    color: colors.white,
    [breakpointMixins.lg]: {
      ...textStyles.bodyS
    },
    "& a": {
      color: colors.white,
      textDecoration: "none"
    }
  },
  inner: {
    paddingTop: variables.spacing.medium,
    paddingBottom: variables.spacing.medium,
    [breakpointMixins.lg]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start"
    }
  },
  footerNavContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: variables.spacing.small,
    flexWrap: "wrap",
    [breakpointMixins.md]: {
      marginTop: variables.spacing.small
    }
  },
  footerNav: {
    display: "flex"
  },
  footerLink: {
    display: "block",
    marginRight: variables.spacing.medium,
    marginBottom: variables.spacing.small
  },
  footerLinkLast: { marginBottom: 0 },
  copyright: {
    marginBottom: variables.spacing.medium,
    [breakpointMixins.md]: {
      marginBottom: 0
    }
  }
};
