import { Link } from "gatsby";
import React from "react";
import Button, { ButtonProps } from "../../Button";

export interface TabButtonProps extends ButtonProps {
  isActive?: boolean;
  linkUrl?: string;
  description: string;
}

const ButtonComponent: React.FC<TabButtonProps> = ({
  children,
  type,
  description,
  isActive,
  ...rest
}) => (
  <Button
    type={type}
    asWrapper={true}
    {...rest}
    active={isActive}
  >
    {description}
  </Button>
);
const TabButton: React.FC<TabButtonProps> = ({ linkUrl, isActive, ...props }) => {
  if (linkUrl) {
    return (
      <Link to={linkUrl} aria-current={undefined} role="tab" aria-selected={isActive}
      >
        <ButtonComponent {...props} />
      </Link>
    );
  }
  return <ButtonComponent {...props} role="tab" ariaSelected={isActive} />;
};

export default TabButton;
