import React from "react";

import { Link } from "gatsby";

import HamburgerMenu from "react-hamburger-menu";
import SocialLinks from "../SocialLinks";

import { handleMinimizePlayer } from "../Player/components/helpers";
import { useNavigation } from "./useNavigation";

import { NavProps } from "./props";

import { styles } from "./styles";
import { colors, baseColors } from "../../styles/colors";

const Nav: React.FC<NavProps> = ({ navItems }) => {
  const { isOpen, setIsOpen, dispatch, navRef } = useNavigation();
  return (
    <>
      <nav
        css={[styles.nav, isOpen ? styles.navOpen : styles.navClosed]}
        ref={navRef}
      >
        <ul css={styles.list}>
          {navItems.map((item, key) => (
            <li
              key={key}
              css={[
                styles.navItem,
                key + 1 === navItems.length && styles.lastItem
              ]}
            >
              <Link
                to={item.path}
                activeClassName={"wmdj-nav-active"}
                onClick={() => {
                  setIsOpen(false);
                  handleMinimizePlayer(dispatch);
                }}
              >
                {item.label}
              </Link>
            </li>
          ))}

        </ul>

      </nav>

      <div css={styles.mobileNav}>
        <div css={styles.mobileSocial}>
          <SocialLinks />
        </div>
        <div css={styles.mobileBurger}>
          <HamburgerMenu
            isOpen={isOpen}
            menuClicked={() => setIsOpen(!isOpen)}
            color={isOpen ? colors.black : baseColors.black}
            css={styles.openIcon}
            width={28}
            height={15}
            strokeWidth={2}
          />
          <span css={styles.menuText}>Menu</span>
        </div>
      </div>
    </>
  );
};

export default Nav;
