import useIsMounted from "ismounted";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { stringToBoolean } from "../helpers/stringToBoolean";

export default function useSessionStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const mounted = useIsMounted();
  const [cookies] = useCookies();
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useEffect(() => {
    if (mounted.current) {
      setStoredValue(() => {
        try {
          if (stringToBoolean(cookies.wmdjFc)) {
            // Get from local storage by key
            const item = window.sessionStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
          }
          return initialValue;
        } catch (error) {
          // If error also return initialValue
          // tslint:disable-next-line: no-console
          console.log(error);
          return initialValue;
        }
      });
    }
  }, [mounted.current]);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value: T) => {
    if (mounted) {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (stringToBoolean(cookies.wmdjFc)) {
          window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        // tslint:disable-next-line: no-console
        console.log(error);
      }
    }
  };

  const removeValue = () => {
    if (mounted) {
      try {
        window.sessionStorage.removeItem(key);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return [storedValue, setValue, removeValue] as const;
}
