import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins, breakpoints } from "../../styles/breakpoints";
import { colors } from "../../styles/colors";
import { variables } from "../../styles/variables";
import { textStyles } from "../../styles/type";

export const styles: EmotionStyles = {
  banner: {
    transition:
      "transform 0.3s ease, max-height 0.1s ease 0.3s, visibility 0.1s ease 0.3s, padding 0.1s ease 0.3s",
    width: "100vw",
    backgroundColor: colors.bookBlack,
    display: "flex",
    justifyContent: "center",
    position: "relative",
    zIndex: 500,
  },
  bannerInner: {
    color: colors.white,
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: variables.maxWidth / 2,
    width: "100vw",
    [breakpointMixins.md]: {
      flexDirection: "row",
      flexWrap: "wrap"
    }
  },
  bannerImage: {
    width: "100%",
    alignSelf: "center",
    [breakpointMixins.md]: {
      width: 400,
      alignSelf: "flex-start",
      marginRight: variables.spacing.large
    },
    [breakpointMixins.lg]: {
      width: "100%",
      alignSelf: "flex-start",
      justifySelf: "center",
      marginRight: variables.spacing.large
    }
  },
  bannerDismissed: {
    transform: "translateY(-100%)",
    maxHeight: 0,
    overflowY: "hidden",
    visibility: "hidden",
    padding: 0
  },
  bannerShown: {
    transform: "translateY(0)",
    maxHeight: 500,
    overflowY: "visible"
  },
  bannerButton: {
    border: "none",
    background: "transparent",
    height: "auto",
    color: colors.white,
    fontSize: 24,
    alignSelf: "flex-end",
    marginBottom: variables.spacing.small,
    position: "absolute",
    top: variables.spacing.medium,
    right: variables.spacing.medium,
    [breakpointMixins.md]: {
      alignSelf: "flex-start",
      order: 3
    }
  },
  tab: {
    padding: `${variables.spacing.xSmall} ${variables.spacing.small}`,
    ...textStyles.bodyXs,
    background: colors.pinkLight,
    cursor: "pointer",
    textAlign: "center",
    zIndex: 500,
    [breakpointMixins.md]: {
      ...textStyles.bodyM,
    },
    [breakpointMixins.lg]: {
      position: "fixed",
      top: 0,
      right: 0,
      ...textStyles.bodyS,
      textAlign: "left",
    },
    "& a": {
      color: colors.black,
      textDecoration: "none"
    }
  }
};
