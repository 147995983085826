import { EmotionStyles } from "../../../interfaces/emotion";
import { CSSObject } from "@emotion/core";
import { colors, baseColors } from "../../../styles/colors";
import { breakpointMixins } from "../../../styles/breakpoints";
import { fonts, fontWeights, textStyles } from "../../../styles/type";
import { variables } from "../../../styles/variables";

export const playerStyles: EmotionStyles = {
  container: {
    padding: variables.spacing.medium
  },
  playerIsOpen: {
    maxHeight: 500
  },
  playerIsClosed: {
    maxHeight: 0,
    overflow: "hidden",
    opacity: 0
  },
  title: {
    fontFamily: fonts.header,
    margin: 0,
    display: "none",
    [breakpointMixins.lg]: {
      display: "inline-block"
    }
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: `${variables.spacing.small} 0`
  },
  scrubber: {
    height: 10,
    padding: `0 ${variables.spacing.small}`
  },
  secondaryControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: `0 0 ${variables.spacing.small}`
  },
  secondaryControlsGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  toggle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: variables.spacing.small
  },
  toggleTitle: {
    color: colors.primary,
    margin: 0
  }
};

export const scrubberStyles: EmotionStyles = {
  "& .bar": {
    minHeight: 8,
    borderRadius: 5,
    backgroundColor: colors.lightGrey
  },
  "& .bar__progress": {
    backgroundColor: colors.coralPink,
    borderRadius: 5
  },
  "& .bar__buffer": {
    borderRadius: 5
  },
  "& .bar__thumb": {
    height: 10,
    width: 10,
    backgroundColor: colors.whamPink,
    borderRadius: "50%"
  }
};

const createButtonSize = (px: number) => {
  return {
    height: px,
    width: px,
    minHeight: px,
    minWidth: px
  } as CSSObject;
};

export const playButtonStyles: EmotionStyles = {
  button: {
    backgroundColor: colors.whamPink,
    borderRadius: "100%",
    border: "none",
    padding: 0,
    color: colors.white,
    fill: colors.white
  },
  regular: createButtonSize(48),
  large: createButtonSize(76),
  iconNotPlaying: {
    marginLeft: 8
  },
  icon_regular: {
    marginTop: 2,
    marginLeft: 0
  },
  icon_large: {
    fontSize: "2.5rem",
    marginTop: 2
  },
  iconSmallNotPlaying: {
    marginLeft: 0
  }
};

export const skipButtonsStyles: EmotionStyles = {
  buttonContainer: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    ...createButtonSize(28),
    border: "none",
    backgroundColor: "transparent"
  },
  back: {
    marginRight: 4
  }
};

export const playbackRateButtonStyles: EmotionStyles = {
  button: {
    ...textStyles.bodyXs,
    fontWeight: fontWeights.heavy,
    border: "none",
    backgroundColor: "transparent"
  }
};

export const timeElapsedSyles: EmotionStyles = {
  container: {
    ...textStyles.bodyXs,
    color: colors.black,
    [breakpointMixins.md]: {
      minWidth: 100
    }
  },
  durationTotal: {
    [breakpointMixins.md]: {
      display: "inline"
    }
  }
};

export const volumeSliderStyles: EmotionStyles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  volumeSlider: {
    width: 70,
    height: 8,
    margin: `3px ${variables.spacing.small} 0`,

    [breakpointMixins.md]: {
      width: 200,
      margin: `3px ${variables.spacing.medium} 0 ${variables.spacing.small}`
    },

    [breakpointMixins.lg]: {
      margin: `3px  ${variables.spacing.large} 0 ${variables.spacing.small}`
    },

    "& .rangeslider": {
      position: "relative",
      backgroundColor: baseColors.grey,
      borderRadius: 4
    },
    "& .rangeslider__fill": {
      background: colors.primary,
      height: 6,
      borderRadius: "4px 0 0 4px"
    },
    "& .rangeslider__handle": {
      position: "absolute",
      height: 12,
      width: 12,
      top: -3,
      borderRadius: "100%",
      backgroundColor: colors.secondary,
      transform: "translateX(-4px)"
    },
    "& .rangeslider__handle-label, & .rangeslider__handle-tooltip": {
      display: "none"
    }
  },
  volumeSliderOpen: {
    width: 100
  },
  iconContainer: {
    width: 18
  },
  volumeIcon: {
    display: "none",
    color: colors.primary,
    [breakpointMixins.md]: {
      display: "inline-block"
    }
  }
};
