import {
  SlimTransistorEpisode,
  TransistorEpisodeInterface
} from "../interfaces/podcasts";
import { getEpisodePath } from "./getEpisodePath";

export const getTranscriptPath = (
  episode: TransistorEpisodeInterface | SlimTransistorEpisode
) => {
  const episodePath = getEpisodePath(episode);
  return `${episodePath}/transcript`;
};
